.wrapper {
  width: 100%;
  height: 100%;
  background: url('./bg.jpg');
  background-size: cover;
}

.container {
  width: 100%;
  padding: 32px 46px;
}

.brand-identity img {
  max-width: 540px;
  width: 100%;
}

.text-container {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-left: 20px;
}

.under-construction-text {
  color: white;
  font-size: 32px;
  font-weight: bold;
}

.email-text {
  color: white;
  font-size: 24px;
  width: fit-content;
}

@media (max-width: 540px) {
  .wrapper {
    background-position: right;
  }

  .container {
    padding: 24px;
  }

  .brand-identity img {
    width: 340px;
  }

  .text-container {
    padding-left: 14px;
    gap: 12px;
  }

  .under-construction-text {
    font-size: 20px;
  }

  .email-text {
    font-size: 18px;
  }
}